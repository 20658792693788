import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Skills = () => (
  <Layout>
    <SEO title="Skills" />
    {/* <p className="temp-note">More projects are on the way!</p> */}
    <h1 className="page-title dark-theme-text">Skills</h1>
    <section className="projects">
      <div className="projects__card">
        <div className="projects__card__title">
          <h3>Under Construction</h3>
        </div>
          <p className="bring-forward">This page will have content soon!</p>
        <div className="projects__card__links">
          <Link to="/" className="btn--primary">Go to Homepage</Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default Skills
